import AllUsers from "../components/AllUsers";
import AllFiles from "../components/file-manager/AllFiles";
import UserIcon from "../Icons/UserIcon";
import FilesIcon from "../Icons/FilesIcon";
import AllOrganisations from "../components/Organisation-manager/AllOrganisations";
import OrganisationUsers from "../components/Organisation-manager/OrganisationUsers";

const appRoutes = [
  {
    path: "/",
    index:true,
    element: <AllUsers />,
    state: "users",
    role: ["Admin"],
    sidebarProps: {
      displayText: "User",
      icon: <UserIcon />
    }
  },
  // {
  //   path: "/files",
  //   index:true,
  //   role:['User', 'Admin'],
  //   element: <AllFiles />,
  //   state: "file",
  //   sidebarProps: {
  //     displayText: "Files",
  //     icon: <FilesIcon />
  //   }
  // },
 {
    path: "/Organisations",
    index:true,
    role:['User', 'Admin'],
    element: <AllOrganisations/>,
    state: "organisations",
    sidebarProps: {
      displayText: "organisation",
      icon: <FilesIcon />
    }
  },
  {
    path: "/Organisations/users",
    // index:true,
    role:['User', 'Admin'],
    element: <OrganisationUsers/>,
    state: "file",
    // sidebarProps: {
    //   displayText: "Files",
    //   icon: <FilesIcon />
    // }
  },

];

export default appRoutes;



